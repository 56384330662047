import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Company } from 'app/anapaya/model/company.model';
import { AbstractService } from 'app/anapaya/service/abstract.service';
import { CompanyService } from 'app/anapaya/service/company.service';
import { tableSort } from 'app/anapaya/utils/table-sort.function';
import { environment } from 'environments/environment';
import { Observable, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResellerService implements AbstractService<Company> {

  filters: {[key: string]: (number|string)[]};
  filteredKeys = ['name'];
  resellers: Company[];

  constructor(
    private http: HttpClient,
    private companyService: CompanyService
  ) { }


  list(
    search?: string | { [key: string]: string},
    sort?: { [key: string]: 'asc' | 'desc'},
    offset?: number,
    limit?: number, options?: { [key: string]: string}
  ): Observable<{ items: Company[]; count: number }> {
    return this.http.get<{resellers: Company[]}>(`${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/resellers`, {withCredentials: true}).pipe(
      map(
        (response: {resellers: Company[]}) => {
          this.resellers = response.resellers;
          return {items: response.resellers, count: response.resellers.length};
        }
      )).pipe(map( (response) => {

        let filtered = response.items.filter(item => !search || item['name'].match(new RegExp(search as string, 'i') ));

        this.filters = {};
        filtered.forEach((item) => {

          this.filteredKeys.forEach((key) => {
            if(!this.filters[key] ) {
              this.filters[key] = [];
            }
            if(!this.filters[key].includes(item[key])) {
              this.filters[key].push(item[key]);
            }
          });
        });

        if(sort) {
          const kSort = Object.keys(sort)[0];
          filtered = filtered.sort((a,b) => tableSort(a[kSort], b[kSort], sort[kSort]));
        }
        if (offset===undefined) {
          offset=0;
        }

        return {items: limit ? filtered.slice(offset, limit+offset) : filtered, count: filtered.length};
      })
    );
  }

  read(entityId: string): Observable<Company> {
    return of({});
  }

  post(entity: Company): Observable<Company> {
    return of({});
  }

  patch(entityId: string, entity: Company): Observable<Company> {
    return of({});
  }

  delete(entityId: string): Observable<boolean> {
    return of(true);
  }

  canWrite(): boolean {
    return true;
  }
}
