import { HttpClient } from '@angular/common/http';
import { Injectable, ɵsetAllowDuplicateNgModuleIdsForTest } from '@angular/core';
import { Deal, DealStatus } from '../model/deal.model';
import { AbstractService } from 'app/anapaya/service/abstract.service';
import { CompanyService } from 'app/anapaya/service/company.service';
import { UserService } from 'app/anapaya/service/user.service';
import { environment } from 'environments/environment';
import { Observable, catchError, forkJoin, map, mergeMap, of } from 'rxjs';
import { ResellerService } from './reseller.service';
import { AbstractNotifier } from 'app/anapaya/service/abstract-notifier.service';
import { NotificationService } from 'app/anapaya/service/notification.service';
import { OrderStatus } from './order.service';
import { keyBy } from 'lodash';

@Injectable({
  providedIn: 'root',

})
export class DealService implements AbstractService<Deal>, AbstractNotifier {
  notificationLabels: string[] = ['provider_pending_deals'];
  statusLabels: {[key in keyof DealStatus as string]?: string};

  constructor(
    private http: HttpClient,
    private companyService: CompanyService,
    private userService: UserService,
    private resellerService: ResellerService,
    public notificationService: NotificationService
  ) {
    this.statusLabels = {
    };
    this.statusLabels[DealStatus.CLOSED_LOST] = 'Lost';
    this.statusLabels[DealStatus.CLOSED_WON] = 'Won';
    this.statusLabels[DealStatus.CLOSING] = 'Closing';
    this.statusLabels[DealStatus.PROPOSAL_SENT] = 'Proposal sent';
    this.statusLabels[DealStatus.OPPORTUNITY] = 'Opportunity';
  }

  read(dealId: string): Observable<Deal> {
    return this.http.get<{resellerDeal: Deal}>(`${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/reseller-deals/${dealId}`, {withCredentials: true}).pipe(
      catchError(
        (err) => {
          throw err;
        }
      ),
      map(
        response =>
          response.resellerDeal
      )
    );
  }

  readAsProvider(resellerId: string, dealId: string): Observable<Deal> {
    return this.http.get<{resellerDeals: Deal[]}>(
      `${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/resellers/${resellerId}/reseller-deals?where[id]=${dealId}`, {withCredentials: true}).pipe(
      catchError(
        (err) => {
          throw err;
        }
      ),
      map(
        response =>
          response.resellerDeals.length === 1 ? response.resellerDeals[0] : response.resellerDeals.find(deal => deal.id === dealId)
      )
    );
  }

  list(search?: string|{[key: string]: string}, sort?: {[key: string]: 'asc'|'desc'}, offset: number=0, limit: number=20, options?: {[key: string]: string}
  ): Observable<{items: Deal[]; count: number}> {
    if(options?.section==='provider') {
      return this.listAsProvider(search, sort, offset, limit);
    }
    const params = { limit, offset };

    if(typeof search === 'string') {
      params['where[name]']=`$ilike:${search}`;
    } else if (search !== undefined) {
      Object.keys(search).forEach((key) => {
        params[`where[$or][${key}]`]=`$ilike:${search[key]}`;
      });
    }

    //managing sort order
    if(sort) {
      const key = Object.keys(sort)[0];
      params[`order[${key}]`]=sort[key];
    }
    return this.http.get<{resellerDeals: Deal[]; count: number}>(`${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/reseller-deals`, {
      withCredentials: true,
      params: params
    }).pipe(
      catchError(
        (err) => {
          throw err;
        }
      ),
      map(
        response => ({items: response.resellerDeals, count: response.count})));
  }

  listAsProvider(search?: string|{[key: string]: string}, sort?: {[key: string]: 'asc'|'desc'}, offset: number=0, limit: number=20): Observable<{items: Deal[]; count: number}> {
    return this.resellerService.list().pipe(
      mergeMap((resellers) => {
        const dealsByResellersObservables: Observable<{resellerDeals: Deal[]}>[] = [];
        resellers.items.forEach( reseller => dealsByResellersObservables.push(
          this.http.get<{resellerDeals: Deal[]}>(
            `${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/resellers/${reseller.id}/reseller-deals`, {withCredentials: true})
        ));
        return forkJoin(dealsByResellersObservables).pipe(map(
          (dealsByResellers) => {
            let deals: Deal[] = [];
            dealsByResellers.forEach((rDeals) => {
              deals = [...deals.map((deal) => {
                  deal.resellerCompany = resellers.items.find(r => r.id === deal.resellerCompanyId); return deal;
                })
                //filtering search
                .filter((elem) => {
                  if(search && typeof search === 'object') {
                    const rgx = new RegExp(search[Object.keys(search)[0]]);
                    return Object.keys(search).some(key => typeof elem[key] !== 'string' || elem[key].match(rgx));
                  }
                  return true;
                }
              ), ...rDeals.resellerDeals];
            });

            return {items: deals.slice(offset, offset + limit), count: deals.length};
          }
        ));
      })
    );
  }

  post(deal: Deal): Observable<Deal> {
    return this.http.post<{resellerDeal: Deal}>(`${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/reseller-deals/`, deal, {withCredentials: true}).pipe(
      map ( response => response.resellerDeal)
    );
  }

  patch(dealId: string, deal: Deal): Observable<Deal> {
    return this.http.patch<{resellerDeal: Deal}>(
      `${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/reseller-deals/${dealId}`, deal, {withCredentials: true}).pipe(
      map( response => response.resellerDeal)
    );
  }

  delete(dealId: string): Observable<boolean> {
    return this.http.delete<any>(`${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/reseller-deals/${dealId}`, {withCredentials: true}).pipe(
      map( response => true)
    );
  }

  canWrite(): boolean {
    return !this.companyService.currentCompany ?
      false :
      (this.companyService.currentCompany.permission.admin || this.userService.userPermissionForPath(this.companyService.currentCompany.id, 'resellerDeals', 'write'));
  }

  notify(label: string): Observable<string>|undefined {
    if (label === 'provider_pending_deals') {
      return of('');
    }
    return undefined;
  }

  refresh(label?: string): void {}

  requestTerm(deal?: Deal): Observable<Deal> {
    return this.http.patch<Deal>(
      `${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/reseller-deals/${deal.id}`,
        {
        term: deal.term,
        isFixedPriceRequested: true
      },
      {withCredentials: true});
  }

  listRequestsAsMaster(): Observable<{items: Deal[]; count: number}> {
    return forkJoin([
      //this.resellerService.list(),
      of(undefined),
      this.http.get<{count: number; resellerDeals: Deal[]}>(
      `${environment.apiUrl}/reseller-deals/`,
      {
        withCredentials: true,
        params: {'where[isFixedPriceRequested]': true,  'where[isFixedPriceApproved]': '$null:true'} //eslint-disable-line
      }),]).pipe(
        map( ([resellers, response]) => {
          /* eslint-disable */
          const items = response.resellerDeals.map( (item) => {
            //item.resellerCompany = resellers.find( reseller => reseller.id === item.resellerCompanyId);
            return item;
          });
          /* eslint-enable */
          return {count: response.count, items: items};
        })
      );
  }
}
