export const tableSort = (a: any, b: any, dir: 'asc'|'desc'): number => {

    if(a === undefined || a === null || a === '') {
      return 1;
    }
    if(b === undefined || b === null || b === '') {
      return -1;
    }

    if (dir === 'asc') {
      return a < b ? -1 : 1;
    } else {
      return a > b ? -1 : 1;
    }
};
