import { ChangeDetectorRef, Injectable } from '@angular/core';
import { Navigation } from 'app/core/navigation/navigation.types';
import { BehaviorSubject, Observable, Subscription, catchError, forkJoin, map, of } from 'rxjs';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { CompanyService } from './company.service';
import { UserService } from './user.service';
import { Permission } from '../model/permission.model';
import { environment } from 'environments/environment';
import { Company } from '../model/company.model';
import { NotificationService } from './notification.service';
import { UserSettingService } from '../user-settings/service/user-setting.service';
import { Contact } from '../model/contact.model';
import { UserSetting } from '../user-settings/model/user-setting.model';


const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'groupdealregistration',
        title: 'Partner managment',
        type: 'collapsable',
        subtitle: '',
        children: [
            {
                id   : 'c1',
                title: 'Deal registration',
                type : 'basic',
                icon : 'heroicons_outline:user-group',
                link : 'deal-registration/deals'
            },
        ],
        meta: {
            permission: {'or': ['registeredDeals']},
            section: ['main', 'admin']
        }
    },
    {
        id: 'groupopo',
        title: 'Quotation / Ordering',
        type: 'collapsable',
        subtitle: '',
        children: [
            {
                id   : 'c1',
                title: 'License ordering',
                type : 'basic',
                icon : 'heroicons_outline:user-group',
                link : 'orders/list',
                meta: {
                    permission: {
                        or: [
                            'orders.read'
                        ]
                    }
                }
            },
            {
                id   : 'c2',
                title: 'License activation',
                type : 'basic',
                icon : 'heroicons_outline:user-group',
                link : 'orders/activable-list',
                meta: {
                    permission: {
                        or: [
                            'contracts.activate'
                        ]
                    },
                    notifications: [{label: 'order.selfStartedConfirmed', permission: ['contracts', 'activate'], turnoffSettingLabel: 'new_licence_to_activate'}]
                }
            },
            {
                id   : 'c1',
                title: 'GATE license ordering',
                type : 'basic',
                icon : 'heroicons_outline:truck',
                link : 'gate',
                meta: {
                    permission: {or: ['gateOrders.write',]},

                }

            },
        ],
        meta: {
            permission: {or: ['orders', 'contracts']},
            section: ['main', 'admin']
        }
    },
    {
        id: 'groupfc',
        title: 'Initial configuration',
        type: 'collapsable',
        subtitle: '',
        children: [
            {
                id   : 'ic1',
                title: 'EDGE - configuration form',
                type : 'basic',
                icon : 'heroicons_outline:cog',
                link : 'forms/edge_configuration_form',
                meta :  {
                    permission: {
                        'or': [
                            `tickets.incidentCategories.${environment.ticketsIncidentCategoriesMapping['EDGE_CONFIGURATION_FORM']}`,
                        ]
                    }
                }
            },
            {
                id   : 'ic2',
                title: 'EDGE - field services',
                type : 'basic',
                icon : 'heroicons_outline:truck',
                link : 'tickets/field_service',
                meta: {
                    permission: {
                        'or': [
                            `tickets.incidentCategories.${environment.ticketsIncidentCategoriesMapping['FIELD_SERVICE']}`,
                        ]
                    }
                }
            },
        ],
        meta: {
            permission: {
                'or': [
                    `tickets.incidentCategories.${environment.ticketsIncidentCategoriesMapping['EDGE_CONFIGURATION_FORM']}`,
                    `tickets.incidentCategories.${environment.ticketsIncidentCategoriesMapping['FIELD_SERVICE']}`,
                ]
            },
            section: ['main', 'admin']
        }
    },
    {
        id: 'grouptickets',
        title: 'Service assurance',
        type: 'collapsable',
        subtitle: '',
        children: [
            {
                id   : 't1',
                title: 'Incident management',
                type : 'basic',
                icon : 'heroicons_outline:support',
                link : 'tickets/incident',
                meta: {
                    permission: {
                        or: [
                            `tickets.incidentCategories.${environment.ticketsIncidentCategoriesMapping['SECURITY_ISSUE']}`,
                            `tickets.incidentCategories.${environment.ticketsIncidentCategoriesMapping['NETWORK_ISSUE']}`,
                            `tickets.incidentCategories.${environment.ticketsIncidentCategoriesMapping['PERFORMANCE_ISSUE']}`,
                            `tickets.incidentCategories.${environment.ticketsIncidentCategoriesMapping['GENERAL_DEFECT']}`,
                        ]
                    }
                }
            },
            {
                id   : 't1',
                title: 'Configuration change req.',
                type : 'basic',
                icon : 'heroicons_outline:clipboard-list',
                link : 'tickets/configuration_change',
                meta: {permission:
                    `tickets.incidentCategories.${environment.ticketsIncidentCategoriesMapping['CONFIGURATION_CHANGE']}`,
                }
            },
            {
                id   : 't1',
                title: 'Release updates',
                type : 'basic',
                icon : 'heroicons_outline:arrow-circle-up',
                link : 'tickets/release_update',
                meta: {
                    permission: `tickets.incidentCategories.${environment.ticketsIncidentCategoriesMapping['RELEASE_UPDATE']}`,
                }
            },
        ],
        meta: {permission: {
            or: [
                `tickets.incidentCategories.${environment.ticketsIncidentCategoriesMapping['SECURITY_ISSUE']}`,
                `tickets.incidentCategories.${environment.ticketsIncidentCategoriesMapping['NETWORK_ISSUE']}`,
                `tickets.incidentCategories.${environment.ticketsIncidentCategoriesMapping['PERFORMANCE_ISSUE']}`,
                `tickets.incidentCategories.${environment.ticketsIncidentCategoriesMapping['GENERAL_DEFECT']}`,
                `tickets.incidentCategories.${environment.ticketsIncidentCategoriesMapping['RELEASE_UPDATE']}`,
                `tickets.incidentCategories.${environment.ticketsIncidentCategoriesMapping['CONFIGURATION_CHANGE']}`,
            ]
        },
        section: ['main', 'admin']
    },
    },
    {
        id: 'ServiceInventory',
        title: 'Service inventory',
        type: 'collapsable',
        subtitle: '',
        children: [
            {
                id: 'c1',
                title: 'List of the ICs',
                type: 'basic',
                icon: 'heroicons_outline:table',
                link: 'service_inventory/list'

            },
        ],
        meta: {
            section: ['main', 'admin'],
            permission: {
                'or': [
                    'contracts.readWithPrices',
                    'contracts.readWithoutPrices'
                ]
            }
        }
    },
    {
        id: 'groupfiles',
        title: 'Document library',
        type: 'basic',
        children: [],
        link: 'agx/files',
        meta: {
            permission: {or: ['files.read']},
            section: ['main', 'admin']
        }
    },
    {
        id: 'groupOrganization',
        title: 'Organization',
        type: 'collapsable',
        subtitle: '',
        children: [
            {
                id   : 'c1',
                title: 'Members',
                type : 'basic',
                icon : 'heroicons_outline:user-group',
                link : 'members',
                meta: {
                    permission: '__admin__'
                }
            },
            {
                id   : 'c1',
                title: 'Company Settings',
                type : 'basic',
                icon : 'heroicons_outline:cog',
                link : 'company-settings',
                meta: {
                    permission: {'and': ['settings.write', '__admin__']}
                }
            },
            {
                id   : 'c1',
                title: 'Notifications',
                type : 'basic',
                icon : 'heroicons_outline:cog',
                link : 'user-settings',
                meta: {
                    permission: 'settings.write'
                }
            },
        ],
        meta: {
            permission: {'or': ['__admin__', 'settings.write']},
            section: ['main', 'admin']
    }
    },
    {
        id: 'groupadmin',
        title: 'Administration',
        type: 'collapsable',
        subtitle: '',
        children: [
            {
                id   : 'c1',
                title: 'Impersonation',
                type : 'basic',
                icon : 'heroicons_outline:user-group',
                link : '/admin/impersonation',
                meta: {
                    permission: 'master.contacts.impersonate'
                }

            },
            {
                id   : 'ic1',
                title: 'EDGE - configuration form',
                type : 'basic',
                icon : 'heroicons_outline:cog',
                link : '/admin/forms/edge_configuration_form',
                meta :  {
                    permission: {
                        'or': [
                            'master.forms.write'
                        ]
                    }
                }
            },
            {
                id   : 'ic1',
                title: 'List of the ICs',
                type : 'basic',
                icon : 'heroicons_outline:table',
                link : '/admin/service_inventory/main_screen',
                meta :  {
                    permission: {
                        'or': [
                            'master.contracts.read'
                        ]
                    }
                }
            },
            {
                id   : 'ic1',
                title: 'Term requests',
                type : 'basic',
                icon : 'heroicons_outline:table',
                link : '/admin/term_requests',
                meta :  {
                    permission: {
                        'or': [
                            'master.resellerDeals.approveFixedPrice'
                        ]
                    }
                }
            }
        ],
        meta: {
            permission: {or: ['master.contacts.impersonate', 'master.forms.write', 'master.contracts.read']},
            onlyMaster: true,
            section: ['main', 'admin']
        }
    },
    {
        id: 'groupagx',
        title: 'GATE Provider',
        type: 'collapsable',
        subtitle: '',
        children: [
            {
                id   : 'agx_provider_end_client',
                title: 'End clients',
                type : 'basic',
                icon : 'heroicons_outline:user-group',
                link : 'agx/provider/end-clients',
                meta: {
                    permission: 'endClients.read'
                }
            },
            {
                id   : 'agx_provider_end_client_deals',
                title: 'Deals',
                type : 'basic',
                icon : 'heroicons_outline:briefcase',
                link : 'agx/provider/deals',
                meta: {
                    permission: 'resellerDeals.read',
                    notifications: [
                        {label: 'provider_pending_price_list_requests', permission: ['providerPriceListRequests', 'manageIncoming'], turnoffSettingLabel: 'price_list_request'}
                    ]
                }
            },
            {
                id   : 'agx_provider_price_list',
                title: 'Price lists',
                type : 'basic',
                icon : 'heroicons_outline:currency-euro',
                link : 'agx/provider/price-lists',
                meta: {
                    permission: 'providerPriceLists.read'
                }
            },
            {
                id   : 'agx_provider_orders',
                title: 'Deliveries',
                type : 'basic',
                icon : 'heroicons_outline:truck',
                link : 'agx/provider/orders',
                meta: {
                    permission: {or: ['providerPriceLists.read', 'resellerDeals.read' ]},
                    notifications: [
                        {label: 'provider_requested_orders', permission: ['resellerDealOrders', 'acknowledge'], turnoffSettingLabel: 'delivery_request'},
                        {label: 'provider_orders_configuration', permission: ['resellerDealOrders', 'acknowledge'], turnoffSettingLabel: 'delivery_configuration'}
                    ]
                }
            },
            {
                id   : 'agx_provider_reports',
                title: 'Reports',
                type : 'basic',
                icon : 'heroicons_outline:document-report',
                link : 'agx/provider/reports',
                meta: {
                    permission: {or: ['provider.reports.read']},
                }
            },
            {
                id   : 'agx_provider_resellers',
                title: 'Resellers',
                type : 'basic',
                icon : 'heroicons_outline:user-group',
                link : 'agx/provider/resellers',
                meta: {
                    //@todo add rights
                    permission: {or: ['providerPriceLists.write']},
                }
            },
        ],
        meta: {
            permission: {or: [
                'endClients.read', 'endClients.write',
                'resellerDeals.read', 'resellerDeals.write',
                'resellerDealOrders.read', 'resellerDealOrders.write',
                'providerPriceLists.read', 'providerPriceLists.write'
            ]},
            companyCanAccess: (company: Company) => company?.isProvider === true,
            section: ['main', 'admin']
        }
    },
    {
        id: 'groupagx',
        title: 'GATE Reseller',
        type: 'collapsable',
        subtitle: '',
        children: [
            {
                id   : 'c1',
                title: 'End clients',
                type : 'basic',
                icon : 'heroicons_outline:user-group',
                link : 'agx/reseller/end-clients',
                meta: {
                    permission: 'endClients.read'
                }
            },
            {
                id   : 'c1',
                title: 'Deals',
                type : 'basic',
                icon : 'heroicons_outline:briefcase',
                link : 'agx/reseller/deals',
                meta: {
                    permission: 'resellerDeals.read'
                }
            },
            {
                id   : 'c1',
                title: 'Price lists',
                type : 'basic',
                icon : 'heroicons_outline:currency-euro',
                link : 'agx/reseller/price-lists',
                meta: {
                    permission: 'providerPriceLists.read'
                }
            },
            {
                id   : 'c1',
                title: 'Deliveries',
                type : 'basic',
                icon : 'heroicons_outline:truck',
                link : 'agx/reseller/orders',
                meta: {
                    permission: {or: ['providerPriceLists.read', 'resellerDeals.read' ]},
                    notifications: [
                        {label: 'reseller_orders_acknowledgment', permission: ['resellerDealOrders', 'send'], turnoffSettingLabel: 'delivery_configuration'},
                        {label: 'reseller_orders_performed', permission: ['resellerDealOrders', 'send'], turnoffSettingLabel: 'delivery'}
                    ]
                }

            },
            {
                id   : 'agx_reseller_reports',
                title: 'Reports',
                type : 'basic',
                icon : 'heroicons_outline:document-report',
                link : 'agx/reseller/reports',
                meta: {
                    permission: {or: ['reseller.reports.read']},
                }
            },
        ],
        meta: {
            permission: {or: [
                'endClients.read', 'endClients.write',
                'resellerDeals.read', 'resellerDeals.write',
                'providerPriceLists.read', 'providerPriceLists.write'
            ]},
            companyCanAccess: (company: Company) => company?.isReseller === true,
            section: ['main', 'admin']
        }
    },


];

const compactNavigation: FuseNavigationItem[] = [

];
const futuristicNavigation: FuseNavigationItem[] = [

];
const horizontalNavigation: FuseNavigationItem[] = [

];


@Injectable({
  providedIn: 'root'
})
export class AnapayaNavigationService {
    public _section = 'main';
    public notifications$: Subscription[];
    refreshed: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _navigation: BehaviorSubject<Navigation> = new BehaviorSubject<Navigation>({
        'compact': [],
        'default': [],
        'futuristic': [],
        'horizontal': []
    });
    private _subscription: Subscription;
    private _currentUser: Contact;
    private disabledNotifications: string[];//{[key: string]: boolean};

    constructor(
        private companyService: CompanyService,
        private userService: UserService,
        private notificationService: NotificationService,
        private userSettingsService: UserSettingService,
    ) {

        this.userService.user$.subscribe(user => this._currentUser = user);

        this.companyService.currentCompany$.subscribe( {
            next: (company) => {
                if (company) {
                    this.userSettingsService.clear('disabled_notifications');

                    this._subscription?.unsubscribe();
                    this._subscription = this.userService.userPermissions(company.id).subscribe(
                        (permissions) => {
                            if(permissions) {
                                this.prepareBuild().subscribe((result) => {
                                    this._navigation.next({
                                        'compact': compactNavigation,
                                        'default': this.setNavigation(company.id, permissions),
                                        'futuristic': futuristicNavigation,
                                        'horizontal': horizontalNavigation
                                    });
                                });

                                //subscription needs to be removed !
                            }
                            else {
                                this._navigation.next({
                                    'compact': compactNavigation,
                                    'default': [],
                                    'futuristic': futuristicNavigation,
                                    'horizontal': horizontalNavigation
                                });
                            }
                        }
                    );
                } else {
                    this._navigation.next({
                        'compact': compactNavigation,
                        'default': [],
                        'futuristic': futuristicNavigation,
                        'horizontal': horizontalNavigation
                    });
                }
            },
            error: () => {}
        });
    }

    /**
     *
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation>
    {
        return this._navigation.asObservable();
    }

  set section(section: string) {
    this._section = section;
  }

  refresh(company?: Company): void {
    if(!company) {
        company = this.companyService._currentCompany;
    }

    this.companyService.currentCompany = company;
  }

  prepareBuild(): Observable<boolean> {
    return this._currentUser ? this.userSettingsService.read(this._currentUser.id+'', 'disabled_notifications').pipe(
        catchError( err => undefined),
        map (
        (result: UserSetting|undefined) => {
            this.disabledNotifications = result && result?.content && typeof result.content !== 'object' ? result.content : [];
            return true;
        }
    )) : of(undefined);
  }

  _buildMenuEntry(companyId: string, navItem: FuseNavigationItem, permission: Permission): FuseNavigationItem {
    const navItemAlt: FuseNavigationItem = {
        id: navItem.id,
        title: navItem.title,
        link: navItem.link?.charAt(0) === '/' ?
                navItem.link :
                '/company/' + companyId + '/' + navItem.link,
        type: navItem.type,
        subtitle: navItem.subtitle,
    };
    navItemAlt.children = [
        ...navItem.children.filter( child => !child.meta?.permission ? true : this.userService.userPermissionForPath(companyId,child.meta?.permission,'read')).map(
        (children) => {
            const child = {
                id: children.id,
                title: children.title,
                type: children.type,
                icon: children.icon,

                // @todo mayby find a better way to build menu entry with companyId...
                link: children.link.charAt(0) === '/' ?
                    children.link :
                    '/company/' + companyId + '/' + children.link,
                badge: children.badge ?? undefined
            };

            this._manageNotifications(companyId, children, child);

            return child;
        })
    ];
    return navItemAlt;
  }

  _manageNotifications(companyId: string, item: FuseNavigationItem, menuItem: FuseNavigationItem): void {
    if(Array.isArray(item.meta?.notifications) && item.meta?.notifications.length) {
        item.meta?.notifications.forEach( (notification, index) => {
            if(notification.label) {

                if(!notification.permission || this.userService.userPermissionForPath(companyId, notification?.permission[0], notification?.permission[1])) {
                    const isDisabled = notification.turnoffSettingLabel ? this.disabledNotifications.includes(notification.turnoffSettingLabel) : false;
                    if(isDisabled === false || isDisabled === undefined) {
                        this.notifications$.push( this.notificationService.notifications(notification.label)?.subscribe(
                            (response) => {

                                if (response && response.length >= 0) {
                                    if(!menuItem.meta) {
                                        menuItem.meta = {};
                                    }
                                    if(!menuItem.meta.notificationData) {
                                        menuItem.meta.notificationData = {};
                                    }
                                    menuItem.meta.notificationData[notification.label] = response;
                                    this._updateBadge(menuItem);
                                }
                            }
                        ));
                    } else {
                        this._updateBadge(menuItem);
                    }

                    this.notificationService.refresh(notification.label);
                }
            }
        });
    }
  }

  _updateBadge(menuItem: FuseNavigationItem): void {
    if (menuItem.meta?.notificationData) {
        const count = this._countDistinct(menuItem.meta.notificationData);
        if(count > 0) {
            menuItem.badge = {
                title: count+'',
                classes: 'px-2 bg-pink-600 text-white rounded-full'
            };
        } else {
            menuItem.badge = undefined;
        }
    } else {
        menuItem.badge = undefined;
    }
    this.refreshed.next(true);
  }

  /**
   * counts the distincts value of a id notifications (strings) list
   */
  _countDistinct(lists: {[key: string]: string[]}): number {
    const mergeList = [];

    Object.keys(lists).forEach((key) => {
        lists[key].forEach( (item) => {
            if(!mergeList.includes(item)) {
                mergeList.push(item);
            }
        });
    });

    return mergeList.length;
  }

  /**
   * Sets navigation entry for a given company for current user given users access rights
   *
   * @param companyId
   * @returns
   */
  setNavigation(companyId: string, permission: Permission): FuseNavigationItem[] {
    //starting with an empty menu & adding one by one authorized paths
    const filteredNav = [];
    this.clearSubscriptions();
    defaultNavigation.forEach( (navItem) => {

        const perm = (!this.companyService.currentCompany?.isMasterCompany && navItem.meta?.onlyMaster) ?
            false :
            (!navItem.meta?.permission ? true : this.userService.userPermissionForPath(companyId, navItem.meta?.permission, 'read'));
        const isSection = navItem.meta?.section && navItem.meta?.section?.includes(this._section);
        const isForCompany = (typeof navItem.meta.companyCanAccess !== 'function') || navItem.meta.companyCanAccess(this.companyService.currentCompany);

        if(perm && isSection && isForCompany) {
            const navItemAlt = this._buildMenuEntry(companyId, navItem, permission);
            filteredNav.push (navItemAlt);
        }

    });
    return filteredNav;
  }

  clearSubscriptions(): void {
    this.notifications$?.forEach(sub => sub?.unsubscribe());
    this.notifications$ = [];
  }
}
